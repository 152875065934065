.ql-toolbar {
    background-color: #282a5b;
    max-width: 342px;
    border-radius: 10px 10px 0px 0px;
}

.ql-toolbar.ql-snow {
    border: 3px solid #282a5b;
}

.ql-toolbar .ql-stroke {
    stroke: #ccc;
}

.ql-toolbar .ql-fill {
    fill: #ccc;
}

.ql-toolbar .ql-picker {
    color: #ccc;
}

.ql-toolbar .ql-picker-options {
    background-color: #282a5b;
}

.ql-container.ql-snow {
    max-width: 342px;
    background-color: #15172a;
    color: #ececec;
    font-family: 'Mulish';
    font-size: 16px;
    border: 3px solid #282a5b;
    border-radius: 0px 0px 10px 10px;
}

.ql-editor img {
    max-width: 100%;
    display: block;
    height: auto;
    border-radius: 5px;
}

.ql-editor a {
    color: #fe3752;
}

.ql-editor {
    padding: 8px;
    border-radius: 10px;
    height: auto;
}

.subscription .ql-container.ql-snow {
    background-color: #282a5b;
    border: 3px solid #15172a;
}

.subscription .ql-toolbar.ql-snow {
    border: 3px solid #15172a;
}

.subscription .ql-toolbar {
    background-color: #15172a;
}

.subscription .ql-toolbar .ql-picker-options {
    background-color: #15172a;
}

.plyr {
    border-radius: 20px;
    margin: auto;
}
