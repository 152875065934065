div.inline p {
    display: inline;
}

.gradientImage {
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.sidebarHover {
    filter: brightness(40%);
}

.sidebarHover:hover {
    filter: brightness(100%);
}

.backgroundShadow {
    position: relative;
}

.backgroundShadow::before {
    content: '';
    position: absolute;
    height: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.5);
}

.textarea {
    display: inline-block;
    width: '100%';
    min-width: -moz-available;
    width: -webkit-fill-available;
    width: stretch;
    resize: none;
    background-color: #333677;
    color: #ececec;
    line-height: 20px;
    margin: 10px;
    padding: 5px 10px;
    border-radius: 20px;
    outline: none;
    overflow-y: scroll;
    max-height: 5em;
    overflow-wrap: break-word;
    font-family: 'Mulish', sans-serif;
}

.textarea::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.textarea {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.textarea[contenteditable]:empty::before {
    content: 'Type a message...';
    color: gray;
    cursor: text;
    font-family: 'Mulish', sans-serif;
}

.invisibleScroll {
    overflow-y: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.invisibleScroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.invisibleScroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.blurredVideo {
    -webkit-filter: blur(20px);
    -moz-filter: blur(20px);
    -o-filter: blur(20px);
    -ms-filter: blur(20px);
    filter: blur(20px);
    overflow: hidden;
}

::selection {
    color: white;
    background: #fe3752;
}

::-moz-selection {
    color: white;
    background-color: #fe3752;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
    -webkit-text-fill-color: #ececec !important;
    font-family: 'Mulish, sans-serif';
    font-size: '15px';
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 20px #282a5b inset !important;
}

.gradientStar {
    /* -webkit-mask-image: -webkit-gradient(linear, 45deg, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 1))); */
    mask-image: linear-gradient(45deg, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 1) 70%);
}

.unselectable {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}
